import { Headers } from './layout/headers/Headers'
import { Footers } from './layout/footers/Footers'
import { useState } from 'react'

const Layout = ({ children, data }) => {
  const [headerPadding, setHeaderPadding] = useState(55)

  return (
    <div className={'flex flex-col'}>
      <Headers
        data={data}
        onHeight={(h) => {
          setHeaderPadding(h)
        }}
      />
      <div
        className="flex-grow min-h-screen"
        style={{ paddingTop: headerPadding }}
      >
        {children}
      </div>
      <Footers data={data} />
    </div>
  )
}

export default Layout
