import Storyblok from '../lib/storyblok/storyblok'
import { PageWrapper } from '../components/PageWrapper'
import { Main } from '../components/Main'
import { getMeta } from '../helpers/getMeta'
import { useStoryblok } from '../lib/storyblok/hooks/useStoryblok'
import { GeneralError } from '../components/atoms/GeneralError'
import { getPageData } from '../helpers/getPageData'

export default function Slug({ preview, data, error }) {
  const enableBridge = preview
  const story = useStoryblok(data?.story, enableBridge, data?.locale)

  if (preview && error) {
    return <GeneralError>{error}</GeneralError>
  }

  return (
    <Main
      data={{ ...data, story: story }}
      preview={preview}
      meta={getMeta({ data })}
    >
      <PageWrapper data={{ ...data, story: story }} preview={preview} />
    </Main>
  )
}

export async function getStaticProps({
  locale,
  locales,
  params,
  preview = false,
}) {
  try {
    const slug = params.slug ? params.slug.join('/') : 'home'
    const data = await getPageData({ slug, locale, locales, preview })

    return {
      props: {
        preview,
        data,
      },
    }
  } catch (e) {
    console.log('error', e)
    return { props: { error: e, preview } }
  }
}

export async function getStaticPaths({ locales, preview }) {
  let { data } = await Storyblok.get('cdn/links/')
  let paths = []

  const links = Object.entries(data.links)
  const linksWithoutFolders = links
    .map((link) => {
      if (!link[1]['is_folder']) {
        return link[1]
      }
    })
    .filter((element) => element !== undefined)

  for (const link of linksWithoutFolders) {
    // get array for slug because of catch all
    const slug = link.slug
    let splittedSlug = slug.split('/')
    if (slug === 'home') splittedSlug = false

    /* Goes to their own page-js, not slug */
    const specialPages = [
      'category',
      'product',
      'checkout',
      'receipt',
      'payment-error',
      '404',
      'page-not-found',
      'settings',
      'search',
      'custom-product-pages',
      'custom-category-pages',
      'layout',
      'my-page',
    ]
    for (const locale of locales) {
      if (!specialPages.find((s) => s === slug)) {
        paths.push({
          params: { slug: splittedSlug },
          locale,
        })
      }
    }
  }

  return {
    paths: paths,
    fallback: false,
  }
}
