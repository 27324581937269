import { getProductImage } from './getProductImage'

export const getMeta = ({ data }) => {
  const title = getMetaTitle({ data })
  const description =
    data?.story?.content?.meta_desc || data?.settingsData?.meta_description
  const image =
    data?.story?.content?.meta_image?.filename ||
    data?.settingsData?.meta_image?.filename

  return {
    title: formatMetaTitle({ title, data }),
    description,
    image,
  }
}

export const getProductMeta = ({ data, CSObject }) => {
  const title =
    data?.product?.metaTitle?.length > 0
      ? data.product?.metaTitle
      : data.product?.name
  const image = getProductImage(data?.product)
  const description =
    data?.product?.metaDescription || data?.settingsData?.meta_description
  const type = 'product'
  const currency = CSObject?.selection?.currency || data?.market?.currency
  const price = data?.product?.priceAsNumber
  const url =
    process.env.NEXT_PUBLIC_SITE_URL + '/product/' + data?.product?.uri

  return {
    title: formatMetaTitle({ title, data }),
    description,
    image,
    type,
    price,
    currency,
    url,
  }
}

export const getCategoryMeta = ({ data }) => {
  const title = () => {
    if (data?.searchQuery) return data.searchQuery

    if (data?.categoryData?.metaTitle?.length > 0)
      return data.categoryData?.metaTitle

    if (data?.categoryData?.name?.length > 0)
      return data.categoryData?.name.slice(-1)[0]

    return getMetaTitle({ data })
  }
  const description = data?.categoryData?.metaDescription
  const image = getProductImage(data?.products?.[0])

  return {
    title: formatMetaTitle({ title, data }),
    description,
    image,
  }
}

const formatMetaTitle = ({ title, data }) => {
  if (data?.settingsData?.site_title?.includes('%title%')) {
    title = data.settingsData?.site_title.replace('%title%', title)
  }

  return title
}

const getMetaTitle = ({ data }) => {
  // Special for receipt since it takes title from first blok. TODO: Add meta on page instead?
  if (data?.story?.content?.component === 'receipt_page') {
    return data?.story?.content?.body?.[0]?.title || 'Receipt page'
  }

  if (data?.story?.content?.title) {
    return data?.story?.content?.title
  }

  return data?.story?.name
}
