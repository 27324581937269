import Layout from './Layout'
import DynamicComponent from './DynamicComponent'

export const PageWrapper = ({ preview, data }) => {
  return (
    <Layout data={data}>
      <DynamicComponent
        blok={data?.story.content}
        data={data}
        preview={preview}
      />
    </Layout>
  )
}
